<template>
  <router-view />
</template>
<script>
import packageInfo from '../package.json'
import config from "tailwindcss/defaultConfig"
import axios from "axios";
import router from "@/router"

export default {
  name: "getDoroApp",
  computed: {
        config() {
            return config
        }
    },

  data: () => ({
    submitted: false,
    patient:[],
    packageInfo,
  }),
  setup (){
    const version = packageInfo.version
    console.log('Doro Form v.' + version + ' Loaded')
    return {
      version
    }
  },
  methods:{
    checkAllowedPatients (patientType) {
      console.log(patientType)
      if(patientType === 'newPatient'){
        router.push({ path: `/meldung/${patientType}` })
      }
    },
    submitForm (data) {
      const userid = localStorage.getItem('userid')
      const string = JSON.stringify(data)
      const formdata = JSON.parse(string)
      console.log('Submit Userid ' + userid )
      this.submitted = axios.post('//api.getdoro.de/mail/index.php?userid='+userid, formdata, {headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }})
       .then((res) => {
         switch (res.data.content.STATUS){
           case 'OK':
             switch(res.data.type) {
               case 'FORMSUBMIT':
                 console.log('FORMSUBMIT',res);
                 router.push({ path: `/meldung/gesendet` })
                 return
               default:
                 return res.data.content.MSG;
             }
           case 'ERROR':
             switch(res.data.type){
               case'REQUIRED':
               default:
                 console.log(res.data.content.MSG)
                 console.log('FORMSUBMIT',res);
                 router.push({ path: `/meldung/fehler`})
                 break;
             }
             break;
           default:
             return ''
         }
       })
       .catch((error) => {
         console.log(error)
         router.push({ path: `/meldung/fehler`})
       }).finally(() => {
         console.log('AXIOS - end')
       });
    }
  },
};
</script>