import { de, en } from '@formkit/i18n'
import { defaultConfig } from '@formkit/vue'
import { createMultiStepPlugin } from '@formkit/addons'
import '@formkit/addons/css/multistep.css'
const config = defaultConfig({
    plugins: [createMultiStepPlugin()],
    locales: { de, en },
    locale: 'de',
    config: {
        classes: {
            label:'text-left',
            wrapper:'mx-auto w-full',
            outer: 'mx-auto w-full text-center',
            input: 'mx-auto w-full',
        }
    }
})
export default config