<template>
    <FormRepeatPrescription />
</template>

<script>
import FormRepeatPrescription from "@/components/FormRepeatPrescription.vue";

export default {
    name: "repeatPrescription",
    components: {FormRepeatPrescription}
}
</script>