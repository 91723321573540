<template>
    <FormTransferNote />
</template>

<script>
import FormTransferNote from "@/components/FormTransferNote.vue";

export default {
    name: "transferNote",
    components: {FormTransferNote}
}
</script>