import '@formkit/themes/genesis'
//import '@formkit/pro/genesis'
import { createApp} from 'vue'
import App from './App.vue'
import { plugin } from '@formkit/vue'
//import { createProPlugin, inputs } from '@formkit/pro'
import config from '../formkit.config.js'
//const pro = createProPlugin('fk-c5acec58ea', inputs)
import './index.css'
import router from './router'
import VueMatomo from 'vue-matomo';
const uri = window.location.search.substring(1);
const params = new URLSearchParams(uri)
const userid = params.get('userid') ?? 'notset'
localStorage.setItem('userid', userid)
const doroApp = createApp(App)
doroApp.config.globalProperties.userid = userid

doroApp.use(plugin, config)
doroApp.use(router)
doroApp.use(VueMatomo, {
    host: "https://track.getdoro.de",
    siteId: 2,
    router: router,
    userId: userid,
});
fetch("https://api.getdoro.de/conf/?userid="+userid)
    .then((response) => response.json())
    .then((textStr) => {
        doroApp.config.globalProperties.texts = textStr
        doroApp.config.globalProperties.config = config
        doroApp.mount("#app")
    })
//doroApp.mount('#app')