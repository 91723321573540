<template>
    <FormAppointmentRequest />
</template>

<script>
import FormAppointmentRequest from "@/components/FormAppointmentRequest.vue";

export default {
    name: "appointmentRequest",
    components: {FormAppointmentRequest}
}
</script>