<template>
    <FormGeneralRequest />
</template>

<script>
import FormGeneralRequest from "@/components/FormGeneralRequest.vue";

export default {
    name: "generalRequest",
    components: {FormGeneralRequest}
}
</script>