<template>
  <Transition>
    <div v-if="userid==='pH7bebe5ugd76giweth'" class="font-sans text-center mt-10">
      <h2 class="text-2xl doroHeadline">Terminanfrage.</h2>
      <div class="text-md mb-2 doroSubLine">Bitte vereinbaren Sie einen Termin mit Doctolib:</div>
      <div class="mb-3 pt-0 mx-auto text-center">
        <a class="doro_button mb-8 block" href="https://www.doctolib.de/endokrinologe/bonn/sofia-antoniou" target="_blank">Innere Medizin, Endokrinologie, Diabetologie</a>
      </div>
      <div class="mb-3 pt-0 mx-auto text-center">
        <a class="doro_button mb-8 block" href="https://www.doctolib.de/internist/essen/matthias-hasselhuhn" target="_blank">Hausärztliche Betreuung und Innere Medizin </a>
      </div>
      <div class="mb-3 pt-0 mx-auto text-center">
        <a class="doro_button mb-8 block" href="https://praxis-fuer-familiengesundheit.de/kontakt/" target="_parent">Telefonischer Kontakt</a>
      </div>
      <buttonFormReset />
    </div>
    <div v-else-if="userid==='lD4ddad1wcc65khqdng'" class="font-sans text-center mt-10">
      <h2 class="text-2xl doroHeadline">Terminanfrage.</h2>
      <div class="text-md mb-2 doroSubLine">Bitte vereinbaren Sie einen Termin mit Doctolib:</div>
      <div class="mb-3 pt-0 mx-auto text-center">
        <a class="doro_button mb-8 block" href="https://www.doctolib.de/neurologie/muenchen/regina-becker" target="_blank">Frau Dr. Regina Becker</a>
      </div>
      <div class="mb-3 pt-0 mx-auto text-center">
        <a class="doro_button mb-8 block" href="https://www.doctolib.de/neurologie/muenchen/holger-kaube" target="_blank">Herr Dr. med. Holger Kaube</a>
      </div>
      <div class="mb-3 pt-0 mx-auto text-center">
        <a class="doro_button mb-8 block" href="https://neuro-kopf.de/contact.html" target="_parent">Telefonischer Kontakt</a>
      </div>
      <buttonFormReset />
    </div>
    <div v-else class="appointmentRequestRoot">
      <FormKit type="form" id="appointmentRequestWrapper" :actions="false" @submit="submitForm">
        <FormKit type="multi-step"
            name="appointmentRequest"
            tab-style="progress" :hide-progress-labels="true"
            class="grid grid-cols-1 place-items-stretch"
            :allow-incomplete="false"
        >
        <FormKit type="step" name="isPatient"
                   :next-label="texts.nextLabel"
                   :prev-label="texts.prevLabel"
                   :beforeStepChange="checkAllowedPatients"
        >
            <h2 class="text-2xl doroHeadline">{{texts.isPatientHeadline}}</h2>
            <div class="text-md mb-2 doroSubLine"></div>
            <FormKit
                v-model="formData.patientType"
                type="radio"
                name="Patientenart"
                preserve="true"
                validation="required"
                :options="[
                            { label: texts.isPatientNew, value: 'newPatient' },
                            { label: texts.isPatientExisting, value: 'existingPatient'}
                          ]"
            />
          <buttonFormReset />
          </FormKit>
         <FormKit type="step" name="appointmentRequest00"
                  :next-label="texts.nextLabel"
                  :prev-label="texts.prevLabel"
         >
          <h2 class="text-2xl doroHeadline">{{texts.AR.appointmentReason.headline}}</h2>
          <div class="text-md mb-3 pt-0 text-left">{{texts.AR.appointmentReason.subLine}}</div>
          <FormKit
           v-model="formData.data.appointmentReason"
           :type="texts.AR.appointmentReason.type"
           :label="texts.AR.appointmentReason.label"
           name="appointmentReason"
           validation="required"
           :placeholder="texts.AR.appointmentReason.placeholder"
           :options="texts.AR.appointmentReason.options"
          />
         </FormKit>
          <FormKit v-if="texts.AR.appointmentAppointee.show" type="step" name="appointmentRequest01"
                   :next-label="texts.nextLabel"
                   :prev-label="texts.prevLabel"
          >
            <h2 class="text-2xl doroHeadline">{{texts.AR.appointmentAppointee.headline}}</h2>
            <div class="text-md mb-3 pt-0 text-left">{{texts.AR.appointmentAppointee.subLine}}</div>
           <FormKit
            v-model="formData.data.appointmentAppointee"
            :type="texts.AR.appointmentAppointee.type"
            :label="texts.AR.appointmentAppointee.label"
            name="appointmentAppointee"
            validation="required"
            :placeholder="texts.AR.appointmentAppointee.placeholder"
            :options="texts.AR.appointmentAppointee.options"
           />

          </FormKit>
          <FormKit type="step" name="DateTime0"
                   :next-label="texts.nextLabel"
                   :prev-label="texts.prevLabel"
          >
            <h2 class="text-2xl doroHeadline">{{texts.AR.date0.headline}}</h2>
            <div class="text-md mb-3 pt-0 text-left">{{texts.AR.date0.subLine}}</div>
              <FormKit
                  type="date"
                  :label="texts.AR.date0.date.label"
                  :placeholder="texts.AR.date0.date.placeholder"
                  name="appointment0Date"
                  validation="required"
                  v-model="formData.data.appointment0Date"
              />
              <FormKit
                  type="select"
                  :label="texts.AR.date0.time.label"
                  :placeholder="texts.AR.date0.time.placeholder"
                  name="appointment0TimeOfDay"
                  validation="required"
                  v-model="formData.data.appointment0TimeOfDay"
                  :options="texts.AR.date0.time.options"

              />
          </FormKit>
          <FormKit v-if="texts.AR.date1.show" type="step" name="DateTime1"
                   :next-label="texts.nextLabel"
                   :prev-label="texts.prevLabel"
          >
            <h2 class="text-2xl doroHeadline">{{texts.AR.date1.headline}}</h2>
            <div class="text-md mb-3 pt-0 text-center">{{texts.AR.date1.subLine}}</div>
                <FormKit
                 type="date"
                 :label="texts.AR.date0.date.label"
                 name="appointment1Date"
                 validation="required"
                 v-model="formData.data.appointment1Date"
              />
              <FormKit
               type="select"
               :label="texts.AR.date0.time.label"
               :placeholder="texts.AR.date0.time.placeholder"
               name="appointment1TimeOfDay"
               validation="required"
               v-model="formData.data.appointment1TimeOfDay"
               :options="texts.AR.date0.time.options"
            />
          </FormKit>
          <FormKit type="step" name="patientData"
                   :next-label="texts.nextLabel"
                   :prev-label="texts.prevLabel">
            <h2 class="text-2xl doroHeadline">{{texts.patientDataHeadline}}</h2>
            <div class="text-md mb-3 pt-0">{{texts.patientDataSubLine}}</div>
            <FormKit
                v-model="formData.firstname"
                type="text"
                :label="texts.formField.firstname.label"
                name="firstname"
                validation="required"
                :placeholder="texts.formField.firstname.placeholder"
            />
            <FormKit
                v-model="formData.name"
                type="text"
                :label="texts.formField.name.label"
                name="name"
                validation="required"
                :placeholder="texts.formField.name.placeholder"
            />
            <FormKit
                type="date"
                :label="texts.formField.birthday.label"
                name="birthday"
                :validation="texts.formField.birthday.validation"
                v-model="formData.birthday"
           />
          </FormKit>
          <FormKit type="step" name="patientContact"
                   :next-label="texts.nextLabel"
                   :prev-label="texts.prevLabel">
            <h2 class="text-2xl doroHeadline">{{texts.patientContactHeadline}}</h2>
            <div class="text-md mb-3 pt-0">{{texts.patientContactSubLine}}</div>
            <FormKit
                v-model="formData.email"
                type="email"
                :label="texts.formField.email.label"
                name="email"
                validation="required|email"
                :placeholder="texts.formField.email.placeholder"
            />
            <FormKit
                v-model="formData.phone"
                type="text"
                :label="texts.formField.phone.label"
                name="phone"
                :placeholder="texts.formField.phone.placeholder"
                :validation="texts.formField.phone.validation"

            />
          </FormKit>
          <FormKit v-if="texts.showForm_insuranceCard" type="step" name="insuranceCard"
                   :next-label="texts.nextLabel"
                   :prev-label="texts.prevLabel">
          <h2 class="text-2xl doroHeadline">{{texts.insuranceCardHeadline}}</h2>
          <div class="text-md mb-2 doroSubLine">{{texts.insuranceCardSubLine}}</div>
          <FormKit
              v-model="formData.data.insuranceCard"
              type="radio"
              :name="texts.formField.insuranceCard.label"
              preserve="true"
              validation="required"
              :options="texts.formField.insuranceCard.options"
          />
        </FormKit>
          <FormKit type="step" name="summary" :prev-label="texts.prevLabel">
            <h2 class="text-2xl doroHeadline">{{texts.summaryHeadline}}</h2>
            <div class="text-md mb-4 pt-0">{{texts.summarySubLine}}</div>
            <div class="text-left grid grid-cols-2 gap-2 mb-8">
              <div>Terminwunsch für:</div>
              <div>{{formData.data.appointmentReason}}</div>
              <div v-if="texts.AR.appointmentAppointee.show">Termin mit:</div>
              <div v-if="texts.AR.appointmentAppointee.show">{{formData.data.appointmentAppointee}}</div>
              <div>Am:</div>
              <div>{{format_date(formData.data.appointment0Date)}} {{format_timeofday(formData.data.appointment0TimeOfDay)}}</div>
              <div v-if="formData.data.appointment1Date">Alternativ:</div>
              <div v-if="formData.data.appointment1Date">{{format_date(formData.data.appointment1Date)}} {{format_timeofday(formData.data.appointment0TimeOfDay)}}</div>
              <div v-if="texts.showForm_insuranceCard">Chipkarte eingelesen:</div>
              <div v-if="texts.showForm_insuranceCard">{{formData.data.insuranceCard}}</div>
              <div class="col-span-2 mt-8">Ihre Daten:</div>
              <div>Vorname:</div>
              <div>{{formData.firstname}}</div>
              <div>Name:</div>
              <div>{{formData.name}}</div>
              <div v-if="formData.birthday">Geburtstag:</div>
              <div v-if="formData.birthday">{{format_date(formData.birthday)}}</div>
              <div>Email:</div>
              <div>{{formData.email}}</div>
              <div v-if="formData.phone">Telefonnummer:</div>
              <div v-if="formData.phone">{{formData.phone}}</div>
            </div>
            <FormKit
                :disabled="isLoading"
                type="submit"
                name="Senden"
                :config="{classes:{
                  outer:'formSendOuter formkit-step-next pr-5 text-right',
                  input:'doro_button'
                }}"
            />
          </FormKit>
        </FormKit>
      </FormKit>
    </div>
  </Transition>
</template>

<script>
import moment from 'moment'
import buttonFormReset from '@/components/ButtonFormReset.vue';
import router from "@/router"
export default {
  name: 'FormAppointmentRequest',
  components: { buttonFormReset },
  methods : {
    format_date(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
    format_timeofday(timeofday) {
      return timeofday === 'am' ? 'Vormittag' : 'Nachmittag'
    },
    submitForm() {
      this.$root.$options.methods.submitForm(this.formData)
    },
    checkAllowedPatients () {
      if(this.formData.patientType === 'newPatient'){
        router.push({ path: `/meldung/${this.formData.patientType}` })
      }
    }
  },
  data () {
    return {
      texts: this.texts
    }
  },
  setup () {
    const userid = localStorage.getItem('userid')
    return {
      userid,
      isLoading : false,
      formData: {
        type        : 'appointmentRequest',
        patientType : '',
        firstname   : '',
        name        : '',
        birthday    : '',
        phone       : '',
        email       : '',
        data    : {
          appointmentReason : '',
          appointmentAppointee : '',
          appointment0Date: '',
          appointment0TimeOfDay: '',
          appointment1Date: '',
          appointment1TimeOfDay: '',
          insuranceCard:''
        }
      }
    }
  }
}

</script>